/* stylelint-disable selector-class-pattern */
.clientDonation {
  --bp-client-color-default: #aac91d;
  --bp-client-color-alpha-6-default: #aac91d99;
  --bp-client-color-alpha-8-default: #aac91dcc;
  --bp-client-color-darker-default: #97b31a;
  --bp-client-color-lighter-default: #c3e237;
  --bp-client-background-color-default: transparent;
  position: relative;
  min-height: 100%;

  box-shadow: none;

  .contentWrapper {
    background: var(--bp-client-background-color, var(--bp-client-background-color-default));
  }

  :global(.btn),
  :global(a.btn),
  :global(a.btn:link),
  :global(a.btn:visited),
  :global(button.btn),
  :global(input.btn) {
    background-color: var(--bp-client-color, var(--bp-client-color-default));
    background-image: none;
    box-shadow: none;
    text-shadow: none;
    border: var(--bp-client-color-daker, none);
    text-decoration: none;

    &:global(.btn-primary),
    &:global(.btn-primary.btn-large) {
      background-image: none;
      text-shadow: none;
    }

    &:global(.btn-spinning),
    &:global(.btn-spinning:hover),
    &:global(.btn-spinning:focus) {
      border: none;
    }

    &:focus,
    &:hover {
      background-image: none;
      box-shadow: none;
      text-shadow: none;
      text-decoration: none;
      background-color: var(--bp-client-color-darker, var(--bp-client-color-darker-default));
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
      background-color: var(--bp-client-color-lighter, var(--bp-client-color-lighter-default));
      border: var(--bp-client-color, var(--bp-client-color-default));
    }
  }

  input[type='email']:focus,
  input[type='text']:focus,
  textarea:focus,
  select:focus,
  :global(.StripeElement--focus) {
    border-color: var(--bp-client-color-alpha-8, var(--bp-client-color-alpha-8-default));
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 7.5%),
      0 0 8px var(--bp-client-color-alpha-6, var(--bp-client-color-alpha-6-default));
  }

  input[type='radio'] + :global(.generic-custom-radio::before) {
    background-color: var(--bp-client-color, var(--bp-client-color-default));
  }

  input[type='checkbox'] + :global(.generic-custom-radio::before) {
    color: var(--bp-client-color, var(--bp-client-color-default));
  }

  :global(.generic-custom-radio-field) input:focus ~ :global(.generic-custom-radio) {
    border-color: var(--bp-client-color, var(--bp-client-color-default));
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 7.5%),
      0 0 8px var(--bp-client-color-alpha-6, var(--bp-client-color-alpha-6-default));
  }

  :global(button.close) {
    background: none !important;
  }

  a:not(:global(.btn)),
  :global(.simulated-link) {
    color: black;
    text-decoration: underline !important;
    font-weight: normal;
  }

  a:not(:global(.btn)):hover,
  :global(.simulated-link):hover {
    color: black;
    text-decoration: none !important;
  }

  :global(.text-muted) {
    color: #90959b;
  }

  :global(.donations-form-terms-of-use) {
    font-size: 14px;
  }

  @mixin desktop {
    .contentWrapper {
      min-width: 960px;
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 0 0 8px 2px #dddddd;
    }
  }

  &.devk {
    --bp-client-color: #008746;
    --bp-client-color-alpha-6-default: #00874699;
    --bp-client-color-alpha-8-default: #008746cc;
    --bp-client-color-darker-default: #006e39;
    --bp-client-color-lighter-default: #00ba60;
    --bp-client-background-color-default: #008746;
    --bp-client-devk-primary-color: #fdc75f;

    :global(.btn.btn-primary) {
      background: var(--bp-client-devk-primary-color);
      color: #005437;

      &:hover,
      &:focus {
        background: var(--bp-client-devk-primary-color);
        color: #ffffff;
      }

      &:active {
        background: var(--bp-client-color);
        color: #ffffff;
      }
    }
  }

  &.fressnapf {
    --bp-client-color: #196428;
    --bp-client-color-alpha-6-default: #19642899;
    --bp-client-color-alpha-8-default: #196428cc;
    --bp-client-color-darker-default: #145020;
    --bp-client-color-lighter-default: #238d38;
    --bp-client-background-color-default: #196428;

    :global(.donation-matching-fund-banner) {
      color: white;

      span {
        background-color: #b1181e;
      }
    }
  }

  &.nussbaum {
    --bp-client-color: #652764;
    --bp-client-color-alpha-6-default: #65276499;
    --bp-client-color-alpha-8-default: #652764cc;
    --bp-client-color-darker-default: #532052;
    --bp-client-color-lighter-default: #8a3588;
    --bp-client-background-color-default: #652764;
    font-family: var(--font-client-nussbaum);
  }

  &.stadtwerkeHanau {
    --bp-client-color: #e3310e;
    --bp-client-color-alpha-6-default: #e3310e99;
    --bp-client-color-alpha-8-default: #e3310ecc;
    --bp-client-color-darker-default: #cb2c0d;
    --bp-client-color-lighter-default: #f25132;
    --bp-client-background-color-default: #e3310e;
    font-family: var(--font-client-stadtwerke-hanau);
  }
}
