.iframe {
  border: var(--betterplace-border-light);
  border-radius: var(--betterplace-border-radius-200);

  --bp-iframe-bg-color-default: transparent;
  --bp-iframe-color-default: #6c9c2e;
  --bp-iframe-shadow-color-default: #6c9c2e99;
  --bp-iframe-border-color-default: #6c9c2ecc;
  --bp-iframe-color-dark-5-default: #5f8f21;

  .iframeFooter {
    background: var(--betterplace-color-fg-light-primary);
    padding: var(--betterplace-sizing-200);

    p {
      color: #919191;
      font-size: 12px;
      margin-bottom: 0;
    }

    svg {
      filter: grayscale(100%);
    }
  }

  .donateIframeForm {
    background: transparent;
    font-size: 14px;
    line-height: 20px;
  }

  :global(.donate-iframe-header) {
    position: relative;
    background-color: var(--bp-iframe-color, var(--bp-iframe-color-default));

    :global(.donate-iframe-header-wrapper) {
      position: relative;
    }

    :global(.donate-iframe-headline) {
      color: white;
      text-align: center;
      padding: 12px 0;

      p {
        margin: 0;
      }

      :global(.line1) {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.3;

        &:only-child {
          line-height: 2.3;
        }
      }

      :global(.line2) {
        font-size: 16px;
      }
    }

    :global(.back-link) {
      color: white;
      text-decoration: underline;
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      align-items: center;
      display: flex;
    }
  }

  :global(.donate-iframe-content) {
    position: relative;
    z-index: 15;
    background: white;
    overflow: hidden;

    :global(.submit-donation-button) > span + span {
      margin-left: 0.25em;
    }
  }

  background-color: var(--bp-iframe-bg-color, var(--bp-iframe-bg-color-default)) !important;

  a:not(:global(.btn)),
  a:not(:global(.btn)):link,
  a:not(:global(.btn)):hover,
  a:not(:global(.btn)):visited,
  a:not(:global(.btn)):active,
  :global(.simulated-link),
  :global(.simulated-link):hover,
  :global(.simulated-link):focus {
    color: var(--bp-iframe-color-dark-5, var(--bp-iframe-color-dark-5-default));
  }

  :global(.generic-tooltip-icon.help):hover {
    background-color: var(--bp-iframe-color, var(--bp-iframe-color-default)) !important;
  }

  :global(.iframeButton),
  :global(.btn) {
    border: var(--bp-iframe-color-darken-5, var(--bp-iframe-color-darken-5-default)) !important;
    background: var(--bp-iframe-color, var(--bp-iframe-color-default)) !important;
    text-shadow: none !important;
  }

  :global(.generic-custom-radio-field) input[type='radio'] + :global(.generic-custom-radio)::before {
    background-color: var(--bp-iframe-color, var(--bp-iframe-color-default));
  }

  :global(.generic-custom-radio-field) input[type='checkbox'] + .generic-custom-radio::before {
    color: var(--bp-iframe-color, var(--bp-iframe-color-default));
  }

  :global(.generic-custom-radio-field) input:focus ~ :global(.generic-custom-radio) {
    border-color: var(--bp-iframe-color, var(--bp-iframe-color-default));
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 7.5%),
      0 0 8px var(--bp-iframe-shadow-color, var(--bp-iframe-shadow-color-default));
  }

  input[type='text']:focus,
  input[type='password']:focus,
  input[type='datetime']:focus,
  input[type='datetime-local']:focus,
  input[type='date']:focus,
  input[type='month']:focus,
  input[type='time']:focus,
  input[type='week']:focus,
  input[type='number']:focus,
  input[type='email']:focus,
  input[type='url']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='color']:focus,
  :global(.generic-custom-radio-field) input:focus ~ :global(.generic-custom-radio),
  select:focus {
    border-color: var(--bp-iframe-border-color, var(--bp-iframe-border-color-default)) !important;
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 7.5%),
      0 0 8px var(--bp-iframe-shadow-color, var(--bp-iframe-shadow-color-default)) !important;
  }

  :global(.generic-progress-bar) :global(.bar) {
    background-color: var(--bp-iframe-color, var(--bp-iframe-color-default));
  }
}
