.formContainer {
  position: relative;
}

.headerLogo {
  grid-area: logo;
  position: relative;
}

@mixin mobile {
  .headerLogo {
    padding-inline: var(--betterplace-spacing-200);
    align-self: center;
  }
}

@mixin mobile {
  .headerLogo img {
    height: var(--betterplace-sizing-400);
    width: auto;
  }
}

@mixin desktop {
  .headerLogo img {
    height: var(--betterplace-sizing-500);
    width: auto;
  }
}

.headerLogo .logoCompact {
  display: none;
}

.headerClaim {
  grid-area: claim;
  color: var(--betterplace-color-bg-neutral);
  white-space: nowrap;
  position: absolute;
  left: 10px;
  top: 25px;
  font: var(--betterplace-typography-label-xs-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 1250px) {
  .headerClaim {
    display: none;
  }
}

.clientHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectsWirwunderHeader {
  background: #ff0000;
}

.projectsWirwunderHeader .logoWirwunder,
.projectsNussbaumHeader .logoGemeinsamHelfen {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

@mixin mobile {
  .projectsWirwunderHeader .logoWirwunder,
  .projectsNussbaumHeader .logoGemeinsamHelfen {
    margin-left: 10px;
    max-height: 40px;
  }
}

@mixin desktop {
  .projectsWirwunderHeader .logoWirwunder {
    margin-left: 51px;
    height: 50px;
  }

  .projectsWirwunderHeader .logoSparkasse {
    margin-right: 26px;
    height: 70px;
  }
}

@mixin mobile {
  .projectsWirwunderHeader .logoSparkasse {
    height: 60px;
    max-width: 210px;
  }
}

.projectsNussbaumHeader {
  background: #662465;
}

@mixin desktop {
  .projectsNussbaumHeader .logoGemeinsamHelfen {
    margin-left: 51px;
    height: 140px;
    width: 254px;
  }

  .projectsNussbaumHeader .logoNussbaum {
    margin-right: 51px;
    height: 140px;
    width: 244px;
  }
}

@mixin mobile {
  .projectsNussbaumHeader .logoNussbaum {
    margin-right: 10px;
    height: 60px;
    max-width: 210px;
  }
}
